import React, {useEffect, useRef} from "react"
import Single from "../../single"
import info1 from "../../../../static/img/irstrat/video-difusion.png";
import noticias12 from "../../../../static/img/blog/noticias-12-.jpg";

const Detail = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })

    return (
        <Single location={props.location} title='Optimismo en el mercado de valores'>
            <div className="main_blog_items">
                <div className="main_blog_item">
                    <div className="main_blog_image img_hover">
                        <img alt={" "} src={noticias12}/>
                    </div>
                    <div className="main_blog_text">
                        <div className="post_info">
                            <a href="#" className="date">
                                08 Dic. 2020
                            </a>
                            <div className="blog_author_area">
                 <span>
                  Por : Rodrigo Hernández
                </span>
                                <span>
                    Análisis del mercado
                </span>
                            </div>
                        </div>

                        <h2>Optimismo en el mercado de valores</h2>
                        <br/>
                    </div>
                </div>
                <div className="s_main_text">
                        <p>De acuerdo con la última encuesta global de administradores de fondos de Bank of America (BofA), realizada
                        entre el 6 y 12 de noviembre, la confianza en el mercado de valores alcanzó su punto más alto en lo que va de
                        2020 con los inversionistas reduciendo drásticamente sus tenencias de efectivo, en favor de los mercados de
                        pequeña capitalización y emergentes.  </p>

                        <p>Lo anterior pudo haber sido impulsado por el anuncio de la primera vacuna efectiva contra el SARS-CoV-2, por
                        parte de Pfizer, durante dicho periodo; hecho que, sumado a la tranquilidad que ya había en el mercado
                        accionario por la victoria proyectada de Joe Biden en las elecciones presidenciales de EE. UU. y a la esperanza
                        de un Congreso estadounidense dividido (que limitaría las posibilidades de aumentos de impuestos o nuevas
                          regulaciones), provocó que se observe un incremento generalizado en el precio de las acciones.</p>

                        <p>De acuerdo con las cifras proporcionadas por los 190 inversionistas encuestados (que en su conjunto
                          administran activos por USD$526,000 mills.), el porcentaje de efectivo disminuyó al 4.1% en noviembre, una
                          caída récord de 1.8 puntos porcentuales en los últimos siete meses. Dicho nivel de efectivo se encuentra incluso
                          por debajo del 4.2% registrado antes del brote de COVID-19. </p>

                        <p>Ante este contexto, los inversores adquirieron activos más volátiles, como títulos de empresas de baja
                        capitalización, bancos y acciones de mercados emergentes; por lo que los bonos, a largo y corto plazo, y el
                        efectivo, son los tres mayores perdedores del último mes. Además, destaca que en noviembre las asignaciones
                        en acciones por parte de los inversionistas aumentaron a una sobreponderación neta del 46% (cerca de un
                          nivel “alcista extremo”), de acuerdo con BofA (los fondos de cobertura mantuvieron una alta exposición de 41%). </p>

                        <p>Actualmente, la mayor preocupación entre los administradores de fondos es la posibilidad de un rebrote de
                        COVID-19; sin embargo, se encuentran optimistas ante el anuncio de una vacuna confiable que podría estar
                        disponible para mediados de enero de 2021. En este sentido, entre los inversores encuestados, el 91% espera
                        que la economía se fortalezca en los próximos 12 meses, y el 66% cree que el crecimiento global se encuentra
                        en una fase de ciclo temprano en lugar de una recesión.</p>

                        <p>Otros aspectos destacados de la encuesta de BofA son los siguientes:</p>

                        <p>• El 73% de los inversores espera una curva de rendimiento más pronunciada en los bonos del Tesoro de EE. UU. para 2021.</p>

                        <p>• El 24% de los inversores encuestados espera que las acciones de valor<sup>1</sup> superen a las acciones de crecimiento<sup>2</sup>.</p>

                        <p>• El 6% de los administradores de fondos aseguran estar asumiendo niveles de riesgo más altos de lo normal.</p>

                        <p>• Los inversores esperan que, durante 2021, los mercados emergentes, el S&P 500 y el petróleo sean los mercados que
                        tengan los mayores rendimientos.</p>

                        <p><small><sup>1</sup>Acciones de valor: Acciones con un precio bajo en relación con el desempeño financiero de la empresa.</small></p>

                        <p><small><sup>2</sup>Acciones de crecimiento: Acciones que presentan ganancias por encima del promedio, con el potencial de crecer más rápido que la economía en general.</small></p>

                        <p>Referencias Bibliográficas
                          <ul>
                            <li>Human,T. (2020). Markets close to ‘full bull’ as investors rotate into stocks. Noviembre 17,2020.  <a href="https://www.irmagazine.com/buy-side/markets-close-full-bull-investors-rotate-stocks" target="_blank">IR Magazine</a>.</li>
                            <li>Galouchko. K. (2020). BofA Says Market Is So Bullish It’s Time to Sell on Vaccine News. Noviembre 17,2020. <a href="https://www.bloomberg.com/news/articles/2020-11-17/bofa-says-market-is-so-bullish-it-s-time-to-sell-on-vaccine-news" target="_blank">Bloomberg</a>.</li>
                          </ul>
                        </p>

                </div>
              </div>
        </Single>
    )
}

export default Detail
