import React, { useEffect, useRef } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/banner"

const Single = ({ location, children, title }) => {
  const isMountedComponent = useRef(true)
  useEffect(() => {
    if (isMountedComponent.current) {
      window.jquery_scripts()
    }
    return () => {
      isMountedComponent.current = false
    }
  })
  return (
    <Layout location={location}>
      <SEO title={title}/>
      <Banner/>
      <section className="main_blog_area single_blog_details section_gap">
        <div className="container">
          <div className="row main_blog_inner">

            <div className="col-lg-12">
              <main>{children}</main>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Single
